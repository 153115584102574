import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Routess from "./routes";
function App() {
  return (
    <Router>
        <Routess/>
    </Router>
  );
}

export default App;
