import React, { useEffect, useRef, useState } from "react";
import { BsMouse } from 'react-icons/bs'
// import { RiTwitterLine } from 'react-icons/ri'
import {RiLinkedinBoxLine } from 'react-icons/ri'
import { FaInstagram } from 'react-icons/fa'
import { FiMail, FiFacebook} from 'react-icons/fi'
import Logo from "../../../_assets/images/logo2.png";
import "./timer.css";
const Header = () => {
 
     // eslint-disable-next-line
  const [timerDay, setTimerDay] = useState<string | number>("00");
  const [timerHour, setTimerHour] = useState<string | number>("00");
  const [timerMinute, setTimerMinute] = useState<string | number>("00");
  const [timerSecond, setTimerSecond] = useState<string | number>("00");

  let interval = useRef<NodeJS.Timer | undefined>();

  const startTimer = () => {
    // const countDowndate = new Date("march 01, 2023 00:00:00").getTime();
    const countDowndate = new Date("july 10, 2024 00:00:00").getTime();

    interval.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDowndate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDay(days.toLocaleString().length <= 1 ? "0" + days : days);
        setTimerHour(hours.toLocaleString().length <= 1 ? "0" + hours : hours);
        setTimerMinute(
          minutes.toLocaleString().length <= 1 ? "0" + minutes : minutes
        );
        setTimerSecond(
          seconds.toLocaleString().length <= 1 ? "0" + seconds : seconds
        );
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });
  const ScrollBottom=()=>{
    const element = document.getElementById('notifyme');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <div className="h-screen">
      {/* <section className="h-screen bg-black relative"> */}
      
      <div className="px-14 py-16 h-full flex flex-col justify-between relative z-1">
        <div className="flex justify-between">
          <img src={Logo} className="w-[45px]" alt="..." />
          <div className="flex space-x-4 items-center">
           {/* <a href="mailto:vinayak.soni98@gmail.com" rel="noopener">
            <RiTwitterLine className="text-4xl text-white hover:text-red-600 duration-200 hover:cursor-pointer"/>
          </a> */}
           <a href="https://www.facebook.com/livenesports/" rel="noopener">
            <FiFacebook className="md:text-4xl text-2xl  text-white hover:text-red-600 duration-200 hover:cursor-pointer"/>
          </a>
           <a href="https://www.instagram.com/livenesports/" rel="noopener">
            <FaInstagram className="md:text-3xl text-2xl text-white hover:text-red-600 duration-200 hover:cursor-pointer"/>
           </a>
           <a href="https://www.linkedin.com/company/livenesports/about/" rel="noopener">
            <RiLinkedinBoxLine className="md:text-4xl text-2xl  text-white  hover:text-red-600 duration-200 hover:cursor-pointer"/>
          </a>
          <a href="mailto:esportsliven@gmail.com" rel="noopener">
            <FiMail className="md:text-4xl text-2xl  text-white hover:text-red-600 duration-200 hover:cursor-pointer"/>
          </a>
          </div>
        </div>
        <div>
          {/* <!-- TIMER --> */}
          <div className="glitch-wrap font-Iceberg glitch">
            <div className="fadein" style={{ display: "block" }}>
              <div className="glitch-clock is-off">
                <div className="glitch-time">
                  <div className="glitch-before">
                    <div className="zero-numb mr-3 md:mr-7">{timerDay}</div>
                    <i className="i0">d</i>
                    <div className="first-numb">{timerHour}</div>
                    <i className="i1">h</i>
                    <div className="time-dott">:</div>
                    <div className="glitch-triangle">
                      <div className="clip-figure">
                        <div className="mask-first">
                          <div className="mask-second">
                            <div className="clip-numb">
                              <div className="second-numb">{timerMinute}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <i className="i2">m</i>
                    <div className="time-dott-sec display-hidden md:inline-block">:</div>
                    <div className="third-numb display-hidden md:inline-block">{timerSecond}</div>
                    <i className="i3 display-hidden md:inline-block">s</i>
                  </div>
                  <div className="glitch-content h-[110px] md:h-auto">
                    <div className="zero-numb mr-0 md:mr-7">{timerDay} </div>
                    <i className="i0 ">d</i>
                    <div className="first-numb">{timerHour}</div>
                    <i className="i1">h</i>
                    <div className="time-dott">:</div>
                    <div className="glitch-triangle">
                      <div className="clip-figure">
                        <div className="mask-first">
                          <div className="mask-second">
                            <div className="clip-numb">
                              <div className="second-numb">{timerMinute}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <i className="i2">m</i>
                    <div className="time-dott-sec display-hidden md:inline-block">:</div>
                    <div className="third-numb display-hidden md:inline-block">{timerSecond}</div>
                    <i className="i3 display-hidden md:inline-block">s</i>
                  </div>
                  <div className="glitch-after">
                    <div className="zero-numb mr-2 md:mr-7">{timerDay}</div>
                    <i className="i0">d</i>
                    <div className="first-numb">{timerHour}</div>
                    <i className="i1">h</i>
                    <div className="time-dott">:</div>
                    <div className="glitch-triangle">
                      <div className="clip-figure">
                        <div className="mask-first">
                          <div className="mask-second">
                            <div className="clip-numb">
                              <div className="second-numb">{timerMinute}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <i className="i2">m</i>
                    <div className="time-dott-sec display-hidden md:inline-block">:</div>
                    <div className="third-numb display-hidden md:inline-block">{timerSecond}</div>
                    <i className="i3 display-hidden md:inline-block">s</i>
                  </div>
                </div>
              </div>
              <div className="glitch-figure">
                <div className="glitch-triang"></div>
              </div>
            </div>
          </div>
          {/* <!-- /TIMER --> */}
          <p className="text-white text-center text-base md:text-3xl font-Intro">
          Only <span className="text-red-600">{timerDay}</span> Days to <br/><span className="text-red-600">Liven</span> Up Your Career
          </p>
        </div>
      <BsMouse onClick={ScrollBottom} className="h-[50px] text-white text-3xl md:text-5xl mx-auto scrolldownmouse hover:text-red-600 duration-200 hover:cursor-pointer"/>     
      </div>
    </div>
  )
}
export default Header