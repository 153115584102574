import { Route, Routes } from "react-router-dom";
//  import AdminLogin from "./admin/login";
import LandingPage from "./pages/home/page";

export const pages = [
    { path: "/", component: <LandingPage /> },
    // { path: "/adminlogin", component: <AdminLogin/> },
  ];
  const Routess = () => {
    return (
      <Routes>
        {pages.map(({ component, path }) => {
          return (
            <Route
              key={path}
              element={component}              
              path={path}
            />
          );
        })}
        {/* <Route component={Page404} /> */}
      </Routes>
      
    );
  };
  export default Routess;