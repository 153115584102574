import Header from './components/header'
// import NotifyMe from './components/NotifyMe';
const LandingPage = () => {
 
  return (
    <>
    <section className="h-full bg-black relative">
      <div className="space"></div>
      <div className="h-screen w-screen border-[6px] border-white fixed z-0 bg-[#1c1c1c66]">
        <div className="h-full w-full border-l-[1px] border-b-[1px] border-cyan-300"></div>
      </div>
    <Header/>
    {/* <NotifyMe/> */}
    </section>
    </>
  );
};
export default LandingPage;
